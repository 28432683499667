import { useState } from "react";
import { supabase } from "../lib/supabase";

export const useSheets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sheetId, setSheetId] = useState<string | null>(null);
  const possibleSteps = [
    "createSheet",
    "addAccounts",
    "addTransactions",
    "done",
  ];
  const [step, setStep] = useState(possibleSteps[0]);

  const createSheet = async () => {
    console.log("Creating sheet");
    setIsLoading(true);
    setStep(possibleSteps[0]);
    setError(null);

    try {
      const {
        data: { session },
      } = await supabase.auth.getSession(); // Get session from Supabase
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user || !session) throw new Error("No user or session data found");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sheets/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
          body: JSON.stringify({ userId: user.id }), // Send userId in the request body
        }
      );

      if (!response.ok) {
        throw new Error(`Error creating sheet: ${response.statusText}`);
      }

      const data = await response.json();
      setSheetId(data.newSpreadsheetId); // Set the sheetId state
      return data; // Return the created sheet info
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to create sheet");
      console.error("Error creating sheet:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const addTransactionsToSheet = async () => {
    console.log("Adding transactions to sheet");
    setIsLoading(true);
    setStep(possibleSteps[2]);
    setError(null);

    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user || !session) throw new Error("No user or session data found");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sheets/add-transactions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
          body: JSON.stringify({ userId: user.id }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error adding transactions to sheet: ${response.statusText}`
        );
      }

      const data = await response.json();
      return data;
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "Failed to add transactions to sheet"
      );
      console.error("Error adding transactions to sheet:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const addAccountsToSheet = async () => {
    console.log("Adding accounts to sheet");
    setIsLoading(true);
    setStep(possibleSteps[1]);
    setError(null);

    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user || !session) throw new Error("No user or session data found");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sheets/add-accounts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
          body: JSON.stringify({ userId: user.id }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error adding accounts to sheet: ${response.statusText}`
        );
      }

      const data = await response.json();
      return data;
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to add accounts to sheet"
      );
      console.error("Error adding accounts to sheet:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const startOnboarding = async () => {
    await createSheet();
    await addAccountsToSheet();
    await addTransactionsToSheet();
  };

  return {
    startOnboarding,
    step,
    sheetId,
    isLoading,
    error,
  };
};
