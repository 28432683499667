import React from "react";
import { Container, Heading, Button, Flex, Text } from "@radix-ui/themes";
import { useAuth } from "../../contexts/AuthContext";

export const Landing = () => {
  const { signInWithGoogle } = useAuth();

  return (
    <Container size="2">
      <Flex
        direction="column"
        gap="4"
        align="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Heading size="8" align="center">
          Welcome to FinLink
        </Heading>
        <Text>hello</Text>
        <Button size="3" variant="soft" onClick={signInWithGoogle}>
          Sign in with Google
        </Button>
      </Flex>
    </Container>
  );
};
