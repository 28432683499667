import React, { useEffect, useMemo, useState } from "react";
import { usePlaidItems } from "../../hooks/usePlaidItems";
import { Flex, Spinner, Text, CheckboxCards, Button } from "@radix-ui/themes";

const AccountSelection: React.FC<{ onComplete: () => void }> = ({
  onComplete,
}) => {
  const {
    accounts,
    error,
    isLoading: isPlaidLoading,
    setActiveAccounts,
  } = usePlaidItems();

  const accountLimit = 2;
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Check if the user has selected the required number of accounts
  const canMoveForward = useMemo(() => {
    return (
      selectedAccounts.length <= accountLimit && selectedAccounts.length > 0
    );
  }, [selectedAccounts]);

  useEffect(() => {
    const activateAccounts = async () => {
      // Check if the user has selected the required number of accounts
      if (
        accounts.length < accountLimit &&
        !isLoading &&
        !error &&
        accounts.length > 0
      ) {
        // Activate all accounts before completing
        const accountIds = accounts.map((account) => account.account_id);
        const response = await setActiveAccounts(accountIds, true); // Call the activate function with all account IDs
        if (response) {
          onComplete();
        } else {
          // TODO error handle
        }
      }

      // Set default selected accounts to the first `accountLimit` accounts
      if (accounts.length > 0) {
        const defaultSelected = accounts
          .slice(0, accountLimit)
          .map((account) => account.account_id);
        setSelectedAccounts(defaultSelected);
      }
    };
    activateAccounts(); // Call the async function
  }, [accounts]);

  // Loading and error states
  if (isPlaidLoading) return <Spinner />;
  if (error) return <Text size="3">Error fetching accounts: {error}</Text>;

  // Render the component
  return (
    <Flex direction="column" gap="4">
      <Flex direction="column" gap="2">
        <Text size="5" weight="bold">
          Select Accounts
        </Text>
        <Text>Please select the accounts you want to connect.</Text>
      </Flex>

      {/* Selected accounts count */}
      <Flex>
        <Flex gap="2">
          <Text
            style={{ fontFamily: "Geist Mono" }}
            color={canMoveForward ? "gray" : "red"}
          >
            {selectedAccounts.length}/{accountLimit}
          </Text>
          <Text color={canMoveForward ? "gray" : "red"}>selected</Text>
        </Flex>
      </Flex>

      {/* Checkbox cards */}
      <CheckboxCards.Root
        value={selectedAccounts}
        defaultValue={selectedAccounts}
        onValueChange={setSelectedAccounts}
        columns={{ initial: "1", sm: "3" }}
      >
        {accounts.map((account) => (
          <CheckboxCards.Item
            key={account.account_id}
            value={account.account_id}
          >
            <Flex direction="column" width="100%">
              <Text weight="bold">{account.name}</Text>
              <Text>{account.subtype}</Text>
            </Flex>
          </CheckboxCards.Item>
        ))}
      </CheckboxCards.Root>

      {/* Footer */}
      <Button
        mt="2"
        variant="solid"
        size="3"
        disabled={!canMoveForward}
        onClick={async () => {
          setIsLoading(true);
          const response = await setActiveAccounts(selectedAccounts, true);
          if (response) {
            onComplete();
          } else {
            // TODO error handle
          }
        }}
      >
        {isLoading ? <Spinner /> : "Done"}
      </Button>
    </Flex>
  );
};

export default AccountSelection;
