import {
  CopyIcon,
  Link1Icon,
  OpenInNewWindowIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { Text, Button, Flex, TextField } from "@radix-ui/themes";

export function SheetCard() {
  const sheetId = "1234567890";

  return (
    <Flex direction="column" gap="4" width="100%">
      <Text size="5" weight="bold">
        My Finlink Sheet
      </Text>

      <TextField.Root
        value={`https://docs.google.com/spreadsheets/d/${sheetId}/edit`}
        contentEditable={false}
        variant="soft"
        size="3"
        onClick={() => {
          navigator.clipboard.writeText(
            `https://docs.google.com/spreadsheets/d/${sheetId}/edit`
          );
        }}
      >
        <TextField.Slot>
          <Link1Icon />
        </TextField.Slot>
      </TextField.Root>

      <Flex justify="between" pr="2">
        <Flex gap="4" align="center">
          <Button
            size="2"
            onClick={() => {
              window.open(
                `https://docs.google.com/spreadsheets/d/${sheetId}/edit`,
                "_blank"
              );
            }}
          >
            <OpenInNewWindowIcon />
            Open
          </Button>

          <Button
            variant="soft"
            size="2"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://docs.google.com/spreadsheets/d/${sheetId}/edit`
              );
            }}
          >
            <CopyIcon />
            Copy Link
          </Button>
        </Flex>

        <Flex>
          <Button size="2" variant="ghost" color="red">
            <TrashIcon />
            Delete
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
