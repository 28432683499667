import { Heading, Flex, Spinner, Text } from "@radix-ui/themes";

import { useOnboarding } from "../../hooks/useOnboarding";
import { Layout } from "../../components/layout/Layout";

export const Settings = () => {
  const { isOnboarded, isLoading } = useOnboarding();

  if (isLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: "100vh" }}>
        <Spinner size="2" />
      </Flex>
    );
  }

  return (
    <Layout
      headerContent={<Heading size="5">Settings</Heading>}
      allowBack={true}
    >
      {/* settings content */}
      <Flex direction="column" gap="4" py="5">
        {/* Additional settings components can be added here */}
        <Text>Settings</Text>
      </Flex>
    </Layout>
  );
};
