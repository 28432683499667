import { useState, useEffect } from "react";
import { usePlaidLink } from "react-plaid-link"; // Assuming you're using react-plaid-link
import { supabase } from "../lib/supabase";

export const useCustomPlaidLink = (completeOnboarding: () => void) => {
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [ready, setReady] = useState<boolean>(false);

  console.log(process.env.REACT_APP_API_URL);

  // Function to fetch the link token
  const fetchLinkToken = async () => {
    const {
      data: { session },
    } = await supabase.auth.getSession(); // Get session from Supabase
    const sessionAuthToken = session?.access_token; // Extract the access token

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/plaid/create-link-token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionAuthToken}`, // Use the session auth token here
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("received link token: ", data);
      return data.link_token;
    } catch (error) {
      console.error("Error fetching link token:", error);
      return null;
    }
  };

  useEffect(() => {
    const getLinkToken = async () => {
      const token = await fetchLinkToken();
      setLinkToken(token);
      setReady(!!token); // Set ready to true if token is fetched successfully
    };
    getLinkToken();
  }, []);

  const config = {
    token: linkToken, // Pass the token here
    onSuccess: async (public_token: string, metadata: any) => {
      // exchange public token for access token
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession(); // Get session from Supabase
        const sessionAuthToken = session?.access_token; // Extract the access token

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/plaid/exchange-public-token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionAuthToken}`, // Now accessible here
            },
            body: JSON.stringify({ public_token }), // Send the public token in the request body
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Public token exchanged successfully:", data);

        // Handle the success case
        completeOnboarding();
      } catch (error) {
        console.error("Error exchanging public token:", error);
      }
    },
    onExit: (err: any, metadata: any) => {
      console.log("onExit", err, metadata);
    },
  };

  // Initialize Plaid when clicked
  const { open } = usePlaidLink(config);

  return { open, ready, handlePlaidLink: open, linkToken };
};
