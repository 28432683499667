import { useEffect, useState } from "react";
import { supabase } from "../lib/supabase";

export const usePlaidItems = () => {
  const [accounts, setAccounts] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAccounts = async () => {
      setIsLoading(true);

      const {
        data: { session },
      } = await supabase.auth.getSession(); // Get session from Supabase
      const sessionAuthToken = session?.access_token; // Extract the access token

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/plaid/accounts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionAuthToken}`, // Ensure to include the auth token if required
          },
        }
      );

      // Log the response status and body for debugging
      const data = await response.json();
      setAccounts(data);
      setIsLoading(false);
    };

    fetchAccounts();
  }, []);

  // Function to activate multiple accounts
  const setActiveAccounts = async (accountIds: string[], isActive: boolean) => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession(); // Get session from Supabase
      const sessionAuthToken = session?.access_token; // Extract the access token

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/supabase/activate-accounts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionAuthToken}`, // Use the session auth token here
          },
          body: JSON.stringify({ accountIds, isActive }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error updating accounts: ${errorData.message}`);
      }

      return true;
    } catch (error) {
      console.error("Error toggling account activation:", error);
    }
  };

  return { accounts, error, isLoading, setActiveAccounts };
};
