import React from "react";
import { Button, Flex, Spinner, Text } from "@radix-ui/themes";
import { LockClosedIcon } from "@radix-ui/react-icons";

interface ConnectBankProps {
  ready: boolean;
  handlePlaidLink: () => void;
}

const ConnectBank: React.FC<ConnectBankProps> = ({
  ready,
  handlePlaidLink,
}) => {
  return (
    <Flex direction="column" gap="2">
      <Text size="5" weight="bold">
        Securely Connect Your Bank
      </Text>
      <Text mb="4">
        Easily and securely link your bank accounts to start tracking your
        transactions. We use Plaid to connect your accounts without storing your
        banking credentials.
      </Text>

      <Button
        size="3"
        onClick={handlePlaidLink}
        disabled={!ready}
        color={ready ? "indigo" : "gray"}
        style={{ opacity: ready ? 1 : 0.75 }}
        variant="solid"
      >
        {ready ? (
          <>
            <LockClosedIcon />
            <Text>Securely Connect Your Bank</Text>
          </>
        ) : (
          <Spinner />
        )}
      </Button>
    </Flex>
  );
};

export default ConnectBank;
