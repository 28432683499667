import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { supabase } from "../lib/supabase";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey!);

export type PlanTier = "regular" | "premium";

interface PlanDetails {
  name: string;
  accounts: number;
  price: number;
  priceId: string; // This will be your Stripe Price ID
}

const PLAN_DETAILS: Record<PlanTier, PlanDetails> = {
  regular: {
    name: "regular",
    accounts: 5,
    price: 5,
    priceId: process.env.REACT_APP_STRIPE_REGULAR_PRICE_ID!,
  },
  premium: {
    name: "premium",
    accounts: 12,
    price: 10,
    priceId: process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID!,
  },
};

export function useStripeCheckout() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handlePayment = async (planTier: PlanTier) => {
    try {
      setIsLoading(true);
      setError(null);

      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error("No user found");

      // Log the plan tier being updated
      console.log("Updating plan tier to:", PLAN_DETAILS[planTier].name);

      // Retrieve the session from Supabase
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) throw new Error("No session found");

      // Update the user's plan tier using the Supabase route
      const planTierResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/supabase/update-plan-tier`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
          body: JSON.stringify({
            userId: user.id,
            planTier: PLAN_DETAILS[planTier].name,
          }),
        }
      );

      if (!planTierResponse.ok) {
        throw new Error(`API error: ${planTierResponse.statusText}`);
      }

      // Create a checkout session with the selected plan
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/stripe/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.id,
            email: user.email,
            priceId: PLAN_DETAILS[planTier].priceId,
            planTier,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`API error: ${response.statusText}`);
      }

      const data = await response.json();
      const { sessionId } = data;
      if (!sessionId) {
        throw new Error("No sessionId returned from API");
      }

      const stripe = await stripePromise;
      const { error: stripeError } = await stripe!.redirectToCheckout({
        sessionId,
      });

      if (stripeError) {
        throw new Error(stripeError.message);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "Payment failed");
      console.error("Payment error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handlePayment,
    isLoading,
    error,
    PLAN_DETAILS,
  };
}
