import { Button, Container, Flex, Separator } from "@radix-ui/themes";
import { UserBadge } from "../UserBadge";
import Footer from "./Footer";
import { motion } from "framer-motion";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";

interface LayoutProps {
  children: React.ReactNode;
  showHeader?: boolean;
  allowBack?: boolean;
  headerContent: React.ReactNode;
  showFooter?: boolean;
}

export function Layout({
  children,
  showHeader = true,
  allowBack = false,
  headerContent,
  showFooter = true,
}: LayoutProps) {
  const navigate = useNavigate();

  return (
    <Container size="2" py="7" style={{ minHeight: "100vh" }}>
      <Flex direction="column" style={{ minHeight: "80vh" }}>
        {showHeader && (
          <>
            <Flex justify="between" mb="4">
              <Flex align="center" gap="4">
                {allowBack && (
                  <Button
                    size="2"
                    radius="full"
                    variant="ghost"
                    color="gray"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowLeftIcon />
                    Back
                  </Button>
                )}
                {headerContent}
              </Flex>

              <UserBadge />
            </Flex>
            <Separator size="4" mb="4" />
          </>
        )}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{
            duration: 0.5,
            ease: [0.4, 0, 0.2, 1],
          }}
        >
          {children}
        </motion.div>
      </Flex>

      <Footer showFooter={showFooter} />
    </Container>
  );
}
