import { Avatar, Box, Card, Flex, IconButton, Text } from "@radix-ui/themes";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { GearIcon } from "@radix-ui/react-icons";
import { ExitIcon } from "@radix-ui/react-icons";

export function UserBadge() {
  const { user, signOut, isLoading } = useAuth();
  const navigate = useNavigate();

  if (isLoading) return <Box p="2">Loading...</Box>;
  if (!user) return null;

  return (
    <Flex align="center" gap="3">
      <Text size="2" weight="medium">
        {user.user_metadata.full_name || user.email}
      </Text>
      <Avatar
        size="1"
        src={user.user_metadata.avatar_url}
        fallback={user.email?.[0]?.toUpperCase() || "?"}
        radius="full"
      />

      <IconButton
        size="2"
        variant="ghost"
        color="gray"
        radius="full"
        onClick={() => navigate("/settings")}
        title="Settings"
      >
        <GearIcon />
      </IconButton>
      <IconButton
        size="2"
        variant="ghost"
        color="gray"
        radius="full"
        onClick={signOut}
        title="Sign out"
      >
        <ExitIcon />
      </IconButton>
    </Flex>
  );
}
