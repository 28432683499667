import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import { AuthProvider } from "./contexts/AuthContext";
import { Theme } from "@radix-ui/themes";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import "./styles/theme.css";

// Pages
import { Landing } from "./pages/landing";
import { Dashboard } from "./pages/dashboard";
import { OnboardingPage } from "./pages/onboarding";
import { Settings } from "./pages/settings";

// Create Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL!;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY!;
export const supabase = createClient(supabaseUrl, supabaseKey);

function App() {
  return (
    <Theme
      accentColor="indigo"
      grayColor="slate"
      radius="large"
      scaling="95%"
      appearance="light"
    >
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/onboarding"
              element={
                <ProtectedRoute>
                  <OnboardingPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </Theme>
  );
}

export default App;
