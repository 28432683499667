import { Heading, Flex, Spinner, Text } from "@radix-ui/themes";

import { useOnboarding } from "../../hooks/useOnboarding";
import { OnboardingCard } from "./OnboardingCard";
import { Layout } from "../../components/layout/Layout";
import { SheetCard } from "./SheetCard";
import { TestCard } from "./testCard";

export const Dashboard = () => {
  const { isOnboarded, isLoading } = useOnboarding();

  if (isLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: "100vh" }}>
        <Spinner size="2" />
      </Flex>
    );
  }

  return (
    <Layout
      headerContent={
        <Heading size="5" color="gray">
          Dashboard
        </Heading>
      }
    >
      {/* onboarding card */}
      <Flex direction="column" gap="4" py="5">
        {!isOnboarded && <OnboardingCard />}
      </Flex>

      {/* sheet card */}
      <Flex direction="column" gap="4" py="5">
        <SheetCard />
      </Flex>

      {/* information card */}
      <Flex direction="column" gap="4" py="5">
        <Text size="5" weight="bold">
          How it works
        </Text>
        <Text size="3" color="gray">
          Your Finlink Sheet is a comprehensive financial summary of your
          finances.
        </Text>
      </Flex>

      {/* test card */}
      <Flex direction="column" gap="4" py="5">
        <TestCard />
      </Flex>
    </Layout>
  );
};
