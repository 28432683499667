import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Card, Text, Button, Heading, Flex } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";

export function OnboardingCard() {
  const navigate = useNavigate();

  return (
    <Flex direction="column" gap="2">
      <Text size="5" weight="bold">
        Get Started
      </Text>
      <Text size="3" color="gray">
        Complete a quick onboarding process to start using all features.
      </Text>
      <Button mt="2" size="3" onClick={() => navigate("/onboarding")}>
        <ArrowRightIcon />
        Start Onboarding
      </Button>
    </Flex>
  );
}
