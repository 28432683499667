import { Text, Button, Flex } from "@radix-ui/themes";
import { useAuth } from "../../contexts/AuthContext";

const WEBHOOK_BASE_URL = "https://4a42-71-105-42-176.ngrok-free.app";

export function TestCard() {
  const userId = useAuth().user?.id;
  const accessToken = useAuth().session?.access_token;

  const handleFireWebhookLocally = () => {
    fetch("http://localhost:3001/api/webhooks/plaid", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: "plaid",
      }),
    });
  };

  const handleFireWebhookRemotely = () => {
    console.log("userId", userId);

    fetch(`http://localhost:3001/api/webhooks/test`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        event: "plaid",
      }),
    });
  };

  return (
    <Flex direction="column" gap="4" width="100%">
      <Text size="5" weight="bold">
        Test
      </Text>

      <Flex gap="4">
        <Button size="2" variant="soft" onClick={handleFireWebhookLocally}>
          Fire Webhook Locally
        </Button>
        <Button size="2" variant="soft" onClick={handleFireWebhookRemotely}>
          Fire Webhook Remotely
        </Button>
      </Flex>
    </Flex>
  );
}
